<template>
  <div class="users-login">
    <Toast position="top-center" />
    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <div
            class="col-12 sm:col-offset-1 sm:col-10 md:col-offset-2 md:col-8 lg:col-offset-3 lg:col-6"
          >
            <div class="card p-fluid">
              <div class="col-12">
                <h1>Login to Your Account</h1>
                <div class="google-btn" @click="gAccountLogin">
                  <div class="google-icon-wrapper">
                    <img
                      class="google-icon"
                      src="@/assets/google/google_g_logo.svg"
                    />
                  </div>
                  <p class="btn-text"><b>Sign in with Google</b></p>
                </div>
              </div>
              <br />
              <router-link to="/privacy-policy">
                <span>Please Read Our Privacy Policy</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";

import Toast from "primevue/toast";
import AppFooter from "@/AppFooter.vue";

import { authLogin, authLogout } from "@/firebase/auth.js";
import UserInfoService from "@/services/user_info_service";
import store from "@/store";

export default defineComponent({
  name: "Login",
  components: {
    Toast,
    AppFooter: AppFooter,
  },

  setup() {
    const toast = useToast();
    const router = useRouter();

    const gAccountLogin = () => {
      authLogin(() => {
        UserInfoService.show()
          .then((response) => {
            console.debug("Login: ", response.data);
            store.dispatch("fetchUserFromBackend");
            store.dispatch("setUserAllow");
            router.push({ name: "ProductTrendResearch" });
          })
          .catch((e) => {
            authLogout(() => {
              console.warn(e);
            });

            if (e?.response?.status === 401) {
              toast.add({
                severity: "error",
                summary: "Error",
                detail: "you have not been in whitelist",
                life: 3000,
              });
            } else {
              router.push({ name: "PublicHome" });
            }
          });
      });
    };

    return {
      gAccountLogin,
    };
  },
});
</script>

<style lang="scss" scoped>
$white: #fff;
$button-active: #eeeeee;
$google-gray: #6a6a6a;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

.google-btn {
  width: 184px;
  height: 42px;
  background-color: $white;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: $white;
  }
  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: $google-gray;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto-Medium";
  }
  &:active {
    background: $button-active;
  }
}
@import url(https://fonts.googleapis.com/css?family=Roboto:500);
</style>
